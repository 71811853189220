import React, { Component } from "react";
import { courseService, networkErrorHelper } from "libs";
import { ROUTES, TAG_AREA } from "const";
import { dateHelper } from "libs/helpers";
import ReactHtmlParser from 'react-html-parser';


import {
    BoxSuccess, MnemoLoading
} from "components/misc";

import {
    Container, Icon, Chip, ChipLabel,
    Row, Button, Col, Card, CardBody, CardFooter
} from 'design-react-kit';
import { Helmet } from "react-helmet";

export class CourseBrowserMUPA extends Component {

    state = {

        loading: true,
        courses: [],
    };

    componentDidMount() {

        this.loadCourses();
    }

    loadCourses() {

        courseService
            .listMUPA()
            .then(({ data }) => {
                const { courses } = data.payload;

                if (courses) {

                    this.setState({ courses, loading: false });
                }
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false });
            });
    }

    renderHeader() {

        const { courses } = this.state;

        return (
            <>
                <div className="image-banner">
                    <img className="w-100" src={`/img/courses/banner_mupa.webp`} alt="" />
                </div>

                {(courses.length > 0) &&
                    <>
                        <div className="m-3 mt-5 m-lg-5 m-sm-3">
                            <p>
                                L'Università Italiana UniCamillus (Saint Camillus International University of Health Sciences)
                                eroga i Master di I livello, che si rivolgono ai laureati che desiderano approfondire le conoscenze nel campo del diritto
                                civilistico, giuridico-amministrativo o pubblico. Grazie alla vasta gamma di argomenti trattati,
                                questi Master rappresentano uno strumento utile per la preparazione ai concorsi pubblici,
                                nonché per il miglioramento delle competenze di coloro che già lavorano nel settore pubblico.
                                Inoltre, conseguire un Master rappresenta un vantaggio nelle graduatorie concorsuali.
                            </p>
                            <hr />
                            <h5><b>Scadenza Iscrizione:  <span className="text-danger">10/04/2025 </span></b></h5>
                            <hr />
                            <h5><a href="/files/corsi/MU/Bando_MU.pdf" target="_blank"><b>Bando di Partecipazione</b></a></h5>
                            <hr />
                            <h5><a href="/files/corsi/MU/Istruzioni_Cart_Docenti_Master_Unicamillus.pdf" target="_blank"><b>
                                Istruzioni su come generare la Carta Docenti solo per i Master Unicamillus</b></a></h5>
                            <h5>
                                <b>FINALITA'</b>
                            </h5>
                            <hr />
                            <p>
                                Tali Master sono spendibili ai fini concorsuali
                                (<a href="https://www.giustizia.it/giustizia/it/mg_1_8_1.page?contentId=SDC273035" target="_blank" rel="noopener noreferrer" >per approfondimenti  clicca qui</a>)
                            </p>
                            <h5>
                                <b>SEDE ESAMI</b>
                            </h5>
                            <hr />
                            <p>Esami in 100 città:</p>
                            <p>
                                Agrigento, Alessandria, Ancona, Andria, Aosta, Arezzo, Avellino, Bari, Belluno, Benevento,  Bergamo, Bologna, Bolzano, Brescia, Brindisi, Cagliari, Caltanissetta, Campobasso, Canicattì, Caserta, Castelvetrano (TP), Catania, Catanzaro, Como, Cosenza, Crotone,  Cuneo, Enna, Ferrara, Firenze, Foggia, Forlì, Frosinone, Genova, Grosseto, Imperia, Ischia, L’Aquila, La Spezia, Latina, Lecce, Livorno, Lodi, Lucca, Macerata/Civitanova Marche, Mantova, Marsala, Matera, Mazara del Vallo, Messina, Milano, Modena, Napoli, Novara, Nuoro, Olbia, Oristano, Padova, Palermo, Parma, Pavia, Perugia, Pesaro Urbino, Pescara, Piacenza, Pisa, Pordenone, Potenza, Prato, Ragusa, Ravenna, Reggio Calabria, Reggio Emilia, Rimini, Roma, Rovigo, Salerno, Sassari, Savona, Sciacca, Siena, Siracusa, Taranto, Teramo, Termini Imerese, Terni, Torino, Trapani, Trento, Treviso, Trieste, Ucria (ME), Udine, Varese, Venezia, Verona, Vibo Valentia, Vicenza, Viterbo e Vittoria.
                            </p>
                            <p>Sessioni:</p>
                            <ul>
                                <li>Sessione di Settembre/Ottobre  <a href="/page/sedi-esami-sessioni-ordinarie" target="_blank">(sedi esame);</a></li>
                                <li>Sessione di Novembre/Dicembre <a href="/page/sedi-esami-sessioni-straordinarie" target="_blank">(sedi esame).</a></li>
                            </ul>
                            <p>
                                L'eventuale cambio di sede d'esame non comporta alcun costo aggiuntivo! Sarà possibile l’apertura di ulteriori sedi, anche all’estero, al
                                raggiungimento del numero minimo previsto di 20 Iscritti
                            </p>
                        </div>
                    </>}
            </>);
    }

    renderCourseList() {

        const { courses } = this.state;

        return (<>
            {(courses.length > 0)
                ?
                (<>
                    <Row>
                        {courses.map((course, i) => (
                            <Col md={6} lg={4} key={`course-${i}`} >
                                <a className="no-pointer" href={`${ROUTES.COURSE_LANDINGPAGE_HS}/${course.code}`} rel="noopener noreferrer" target="_blank">
                                    <article
                                        className='card-wrapper card-space pointer'  >
                                        <Card noWrapper className="card-bg card-img shadow">
                                            <CardBody>
                                                <div className="box-300">
                                                    <p className="width-full text-center text-green m-0"><b>{course.label}</b></p>
                                                    <p className="text-green">
                                                        {ReactHtmlParser(course.title)}</p>
                                                    <hr />
                                                    <Icon icon="it-chart-line" className="icon-sm mr-2" />{course.subTitle} <br />
                                                    <Icon icon="it-clock" className="icon-sm mr-2" /><b>Scadenza</b>: {dateHelper.toITDate(course.enrollDeadline)} <br />
                                                    {(course.price) && (<><Icon icon="it-card" className="icon-sm mr-2" /><b>Prezzo</b>: {course.price}<br /></>)}
                                                    {course.tagSchool && (<Chip className="m-1"><ChipLabel>Scuola {course.tagSchool}</ChipLabel></Chip>)}
                                                    {course.tagArea && (
                                                        <>
                                                            {course.tagArea.split(',').map((tag, j) => (
                                                                <Chip key={`tagArea-${j}`} className="m-1" color="primary" ><ChipLabel>{TAG_AREA[tag]}</ChipLabel></Chip>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                                <CardFooter >
                                                    <Button className="float-right" color="primary" outline>
                                                        Iscriviti
                                                    </Button>
                                                </CardFooter>
                                            </CardBody>
                                        </Card>
                                    </article>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </>)
                : <BoxSuccess>La ricerca non ho prodotto alcun risultato.</BoxSuccess>
            }

        </>);
    }

    render() {

        const { loading } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        const courseListSection = this.renderCourseList();
        const header = this.renderHeader();

        const title = '';
        const image = '/img/courses/banner_mupa.webp';

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                </Helmet>
                <section className="my-4">
                    <Container>
                        {header}
                        {courseListSection}
                    </Container>
                </section>
            </>
        );
    }
}
