import { ValidatedForm } from "components/forms";
import React from "react";
import { Col, Row } from "design-react-kit";
import { TitoliProfessionali60CFU } from "./enroll60CFU/TitoliProfessionali60CFU";
import { enrollmentService, networkErrorHelper, payloadBuilder } from "libs";
import ReactHtmlParser from "react-html-parser";
import { ENROLLMENT_STEPS } from "const";

export class EnrollFillForm60CFUStep3 extends ValidatedForm {
  state = {
    _formRefOther: React.createRef(),
    loading: false,
    defaultValues: {},
    validationSummary: "",
    currentCompetitionClass:
      this.props.enrollData.pluginConfig.competitionClass || "",
  };

  onSubmit = () => {

     let validationList = [];
    let isValid = true;
    let qualifications = [];
    this.payload = payloadBuilder(this.state);

    //--- titleStep3 ---
    const { _formRefOther } = this.state;

    // titoli professionali
    if (_formRefOther) {
      const checkValidation = _formRefOther.current.checkValidation();

      if (!checkValidation ) {
        validationList.push("Verificare i dati evidenziati in rosso.");
        isValid = false;
      }  else{

        if (!_formRefOther.current.onSubmit()) {
          validationList.push("Verificare i dati evidenziati in rosso");
          isValid = false;
        } else {

          const payload = _formRefOther.current.getPayload();
          qualifications.push(...payload);
        }
      }     

    }

    this.payload.qualifications = qualifications;

    if (isValid) {
      const payloadQualification = {
        idEnrollment: this.props.enrollData.idEnrollment,
        currentStep: ENROLLMENT_STEPS.FILL3,
        qualifications: qualifications,
      };

      //  invia al server (array di userEnrollmentQualification)
      function myFunction() {
        return new Promise((resolve, reject) => {
          enrollmentService
            .updateEnrollmentQualification(payloadQualification)
            .then((response) => {
              console.log("response", response);
              resolve();
            })
            .catch((errors) => {
              validationList.push("Errore imprevisto nell'invio dei dati. Se il problema persiste contattare il supporto tecnico.");
              networkErrorHelper.notify(errors);
              isValid = false;
            });
        });
      }

      // Call the function and await the result
      myFunction()
        .then((response) => {
          console.log("response", response);
        })
        .catch((errors) => {
          validationList.push("Errore imprevisto nell'invio dei dati. Se il problema persiste contattare il supporto tecnico.");
          networkErrorHelper.notify(errors);
          isValid = false;
        });
    }

    let validationSummary = "";

    if (validationList.length > 0) {
      validationSummary = '<ul><li>' + validationList.join("</li><li>") + '</li></ul>';
    }
    this.setState({ validationSummary });
    return isValid;
  };

  getPayload = () => {
    return this.payload;
  };

  //--- ---

  render() {
    const { loading, _formRefOther, validationSummary } = this.state;
    if (loading) return <></>;

    return (
      <>
        <Row className="mt-2">
          <Col>
            <h6>TITOLI PROFESSIONALI </h6>          
            <TitoliProfessionali60CFU
              ref={_formRefOther}
              skipCC={false}
              competitionClass={
                this.state.currentCompetitionClass
              }
            />
          </Col>
        </Row>

        <Row>
          <div className="w-100">
            {validationSummary && (
              <div className="alert alert-danger mt-3" role="alert">
                {ReactHtmlParser(validationSummary)}
              </div>
            )}
          </div>
        </Row>
      </>
    );
  }
}
