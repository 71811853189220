import React from "react";
import { ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";
import { Button } from "design-react-kit";
import { TitoliProfessionali } from "./TitoliProfessionali";

export class TitoliProfessionali60CFU extends ValidatedForm {
  _formsTitoliProfessionali = [];

  state = {
    defaultValues: {},
    validationError: "",
    numTitoliProfessionaliMultipli: [],
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  ERROR_MESSAGES = {};

  onSubmit = () => {

    let isValid = true;

    if (this._formsTitoliProfessionali.length > 0) {
      this._formsTitoliProfessionali.forEach((form) => {
        if (form && form.current != null) {
          isValid = form.current.checkValidation();
        } else {
          isValid = false;
        }
      });
    }

    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];
    this.payload = payloadBuilder(this.state);

    if (this._formsTitoliProfessionali.length > 0) {
      this._formsTitoliProfessionali.forEach((form) => {
        if (form && form.current != null) {
          // get payload
          const payloadTitoliProfessionali = form.current.getPayload();
          payloadOther.push(payloadTitoliProfessionali);
        }
      });
    }

    return payloadOther;
  };

  addTitoliProfessionali = () => {
    this.state.numTitoliProfessionaliMultipli.push(
      this.state.numTitoliProfessionaliMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numTitoliProfessionaliMultipli: this.state.numTitoliProfessionaliMultipli,
    });
  };

  removeTitoliProfessionali = (idx) => {
    if (this.state.numTitoliProfessionaliMultipli.length <= 0) {
      return true;
    }

    this.state.numTitoliProfessionaliMultipli.splice(idx, 1);
    this._formsTitoliProfessionali.splice(idx, 1);

    this.setState({
      ...this.state,
      numTitoliProfessionaliMultipli: this.state.numTitoliProfessionaliMultipli,
    });
  };

  render() {

    return (
      <>
        <p>Servizio di insegnamento prestato sulla specifica classe di
          concorso e posti di sostegno del medesimo grado, valutato ai
          sensi dell'articolo 11, comma 14, della legge n. 124 del 1999
          prestato nelle scuole del sistema nazionale di istruzione, nonché
          nell'ambito dei percorsi di cui all'articolo 1, comma 3, del
          decreto legislativo 15 aprile 2005, n. 76, relativi al sistema di
          istruzione e formazione professionale. È altresì valutato il
          servizio prestato nelle forme di cui al comma 3 dell'articolo 1 del
          decreto-legge 25 settembre 2009, n. 134, convertito, con
          modificazioni, dalla legge 24 novembre 2009, n. 167, nonché di cui
          al comma 4-bis dell'articolo 5 del decreto-legge 12 settembre 2013,
          n. 104, convertito, con modificazioni, dalla legge 8
          novembre 2013, n. 128.</p>
        <p>
          <b>Annualità di servizio:</b>
          <p>
            Indicare eventuali annualità intere di servizio svolte. Ogni annualità corrisponde
            ad almeno 180 giorni di servizio, oppure al periodo dal 1° febbraio al termine delle 
            operazioni di scrutinio finale. Periodi di servizio sovrapposti, debbono essere conteggiati una sola volta.
          </p>
        </p>
        {(this.state.numTitoliProfessionaliMultipli || []).map((_, idx) => {
          this._formsTitoliProfessionali[idx] = React.createRef();

          return (
            <div
              key={`titoliProfessionali=${idx}`}
              className="m-0 ml-5 text-secondary"
            >
              <h6>Annualità di servizio n. {idx + 1}</h6>
              <Button
                className="mb-3 btn btn-sm btn-danger"
                onClick={() => {
                  this.removeTitoliProfessionali(idx);
                }}
              >
                Rimuovi
              </Button>
              <div className="p-3 border border-secondary rounded-lg">
                <TitoliProfessionali
                  ref={this._formsTitoliProfessionali[idx]}
                  key={`titoliProfessionali=${idx}`}
                  idx={idx}
                  checkAA={false}
                  skipCC={this.props.skipCC}
                  competitionClass = {this.props.competitionClass}
                  qualificationType="Title31Item_TitoliProfessonali"
                  qualificationCode="title31"
                  template="Title31Item_TitoliProfessonali"
                  extCode ="TITOLARE_CONTRATTO"    
                />
              </div>
            </div>
          );
        })}
        <div className="mt-3 ml-5 text-secondary">
          <Button
            className="btn btn-sm btn-primary"
            onClick={this.addTitoliProfessionali}
          >
            Aggiungi
          </Button>
        </div>
      </>
    );
  }
}
