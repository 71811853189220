/* eslint-disable no-dupe-keys */
import {proxyBE, proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';

export const courseService = {

    loadSection(id) {
        return proxyBE.get(`sections/${id}`);        
    },
    
    listAll(courseType){
        return proxyBE.get('courses/listAll/'+courseType);
    },
  
    listPLUA(){
        return proxyBE.get('courses/listPLUA');
    },

    listMUPA(){
        return proxyBE.get('courses/listMUPA');
    },

    listAllMaster(){
        return proxyBE.get('courses/listAllMaster/');
    },

    listHomeTypes(){
        return proxyBE.get('courses/homeTypes');
    },

    lisTags(courseType){
        return proxyBE.get('courses/tags/'+courseType);
    },

    listByCompetitionClass(courseType, company=''){

        let url = 'courses/competitionClasses/'+courseType;
        if(company){
            url += '/'+company;
        }
        return proxyBE.get(url);
    },

    listAdminByCompetitionClass(courseType, company=''){

        let url = '/admin/courses/competitionClasses/'+courseType;
        if(company){
            url += '/'+company;
        }
        return proxyBE.get(url);
    },
    
    listCourseRegion($company, $competitionClass){
        return proxyBE.get(`courses/competitionClasses-region/${$company}/${$competitionClass}`);
    },

    listCompetitionClassAll(){
        return proxyBE.get('courses/competitionClasses-all/');
    },

    getByCode(courseCode){
        const currentAA = parseInt(process.env.REACT_APP_AA_YEAR);
        return this.getByCodeAndAA(courseCode, currentAA);
    },

    getByCodeAndAA(courseCode, currentAA){
        return proxyBE.get('course/'+courseCode+'/'+currentAA);
    },

    async getCourseRates(courseCode){
        const payload = mnemoService.buildPayload('courserate', 'courseCode', courseCode);
        return proxyApiMnemo.get('enrollment/?payload='+encodeURI(payload));        
    },    

    async cfuActiveLink(registrationCode){
        const payload = mnemoService.buildPayload('cfuActiveLink', 'registrationCode', registrationCode);
        return await proxyApiMnemo.get('dashboard/?payload='+encodeURI(payload));        
    },

    async mneActiveLink(registrationCode){
        const payload = mnemoService.buildPayload('mneActiveLink', 'registrationCode', registrationCode);
        return await proxyApiMnemo.get('dashboard/?payload='+encodeURI(payload));        
    },

    async perfezionamentoActiveLink(registrationCode){        
        const payload = mnemoService.buildPayload('perfezionamentoActiveLink', 'registrationCode', registrationCode);
        return await proxyApiMnemo.get('dashboard/?payload='+encodeURI(payload));        
    },
   
    async myManifestoURL(registrationCode){
        const payload = mnemoService.buildPayload('manifestoURL', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },

    async myTutor(registrationCode){
        const payload = mnemoService.buildPayload('Tutor', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },

    async getMFPassword(registrationCode){
        const payload = mnemoService.buildPayload('MFPassword', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },

    async getCourseModules(courseCode){
        const payload = mnemoService.buildPayload('courseModules', 'courseCode', courseCode);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));
    },

    async getCourseAllSingleModules(){
        const payload = mnemoService.buildPayload('getCourseAllSingleModules');
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));
    },

    async myCourseModulesCFU(registrationCode){
        const payload = mnemoService.buildPayload('myCourseModulesCFU', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },

    async myCourseModules(registrationCode){
        const payload = mnemoService.buildPayload('myCourseModules', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },

    async saggioFinaleExist(registrationCode){
        const payload = mnemoService.buildPayload('saggioFinaleExist', 'registrationCode', registrationCode);
        return await proxyApiMnemo.get('dashboard/?payload='+encodeURI(payload));        
    },
    
    async listSSD(courseFilter){
        const payload = mnemoService.buildPayload('courseSSD', 'courseFilter', courseFilter);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },

    async listWord(courseFilter){
        const payload = mnemoService.buildPayload('courseByModuleTitle', 'courseFilter', courseFilter);
        return proxyApiMnemo.get('course/?payload='+encodeURI(payload));        
    },       
}