import React, { Component } from "react";
import {
  BoxInfo,
  ErrorNotification,
  MnemoLoading,
  MSection,
} from "components/misc";
import { Button, Container, Row, Col, Icon } from "design-react-kit";
import { MSelect } from "components/forms";
import { courseService, dateHelper, networkErrorHelper } from "libs";
import { enrollmentEvaluationService } from "libs/api/enrollmentEvalutaionService";

export class AnalysisEvaluationsCFU extends Component {


  emptyFields = {
    company: "",
    competitionClass: "",
    region: "",
    cfuType:""
  };

  state = {
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },

    loading: false,
    courseCompetitionClass: [],
    payloadCurrentCC: [],
    payloadCurrentRegion: [],
    currentList: [],

    currentCompany: "",
    currentCC: "",
    currentRegion: "",
    currentCfuType: "",
  };

  optionsPayload = {
    company: [{ label: "filtro sull'ente", value: "" }],
    competitionClass: [{ label: "filtro sulla classe di concorso", value: "" }],
    region: [{ label: "filtro sulla regione", value: "" }],
  };
  
  searchField = [
    {
      field: "cfuType",
      name: "cfuType",
      label: "CFU",
      payload: {
        options: [
          { label: "filtro sui CFU", value: "" },
          { label: "60", value: "ABILITAZIONI60CFU_BANDO" },
          { label: "30", value: "ABILITAZIONI30CFU_BANDO" },        
        ],
      }, component: MSelect,
      onChange: (_, value) => this.onChangeCfuType(_, value),
    },
    {
      field: "company",
      name: "company",
      label: "Ente",
      payload: {
        options: [
          { label: "filtro sull'ente", value: "" },
          { label: "Unicamillus", value: "unicamillus" },
          { label: "IUL", value: "iul" },
        ],
      },
      component: MSelect,
      onChange: (_, value) => this.onChangeCompany(_, value),
    },
    {
      field: "competitionClass",
      name: "competitionClass",
      label: "Classe di Concorso",
      component: MSelect,
      onChange: (_, value) => this.onChangeCompetitionClass(_, value),
    },
    {
      field: "region",
      name: "region",
      label: "Regione",
      component: MSelect,
      onChange: (_, value) => this.onChangeRegion(_, value),
    },
  ];

  loadRemote = async () => {
    this.setState({ loading: true });
    courseService
      .listAdminByCompetitionClass("ABILITAZIONI60CFU_BANDO")
      .then(({ data }) => {
        this.setState({
          loading: false,
          courseCompetitionClass: data.payload.courseCompetitionClass,
        });
      })
      .catch((error) => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  };

  componentDidMount() {
    this.loadRemote();
  }

  onChangeCfuType = (_, value) => {
    this.setState({ currentCfuType: value });
  };

  onChangeCompany = (_, value) => {
    const { courseCompetitionClass } = this.state;
    const payloadCC = [];
    payloadCC.push({
      label: "filtro sulla classe di concorso",
      value: "",
    });

    // add dinstict competition class  of current company from courseCompetitionClass
    courseCompetitionClass.forEach((element) => {
      if (element.company === value) {
        if (
          payloadCC.findIndex((x) => x.value === element.competitionClass) === -1
        ) {
          payloadCC.push({
            label: element.competitionClass + " - " + element.note,
            value: element.competitionClass,
          });
        }
      }
    });

    this.setState({
      payloadCurrentCC: payloadCC,
      currentCompany: value,
      currentList: [],
    });
  };

  onChangeCompetitionClass = (_, value) => {
    const { courseCompetitionClass, currentCompany } = this.state;
    const payloadRegion = [];
    payloadRegion.push({
      label: "filtro sulla regione",
      value: "",
    });

    // add dinstict region of current competitionClass and currentcompany from courseCompetitionClass
    courseCompetitionClass.forEach((element) => {
      if (
        element.competitionClass === value &&
        element.company === currentCompany
      ) {
        if (payloadRegion.findIndex((x) => x.value === element.region) === -1) {
          payloadRegion.push({
            label: element.region,
            value: element.region,
          });
        }
      }
    });

    this.setState({
      payloadCurrentRegion: payloadRegion,
      currentCC: value,
      currentList: [],
    });
  };

  onChangeRegion = (_, value) => {
    this.setState({ currentRegion: value, currentList: [] });
  };

  onSearchForm = () => {
    this.loadStudents();
  };

  loadStudents = () => {

    //this.setState({ loading: true, loadingError: "" });
    const { currentCC, currentCompany, currentRegion, currentCfuType } = this.state;
    if (currentCompany && currentCC && currentRegion && currentCfuType) {
      enrollmentEvaluationService
        .evaluationList(currentCompany, currentCC, currentRegion, currentCfuType)
        .then(({ data }) => {
          const { enrollmentEvaluations } = data.payload;
          this.setState({ currentList: enrollmentEvaluations, loading: false });
        })
        .catch((error) => {
          console.log(error);
          if (!networkErrorHelper.is404(error)) {
            networkErrorHelper.notify(error);
          }

          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false, loadingError: "" });
      ErrorNotification.render(
        "Devi inserire tutti i dati prima di proseguire "
      );
    }
  };

  analyzeStudents = (e) => {
    e.preventDefault();
    this.setState({ loading: true, loadingError: "" });
    const { currentCC, currentCompany, currentRegion, currentList, currentCfuType } =
      this.state;
    if (currentCompany && currentCC && currentRegion && currentCfuType) {
      if (currentList.length === 0) {
        ErrorNotification.render("Nessuna domanda da analizzare");
        this.setState({ loading: false });
      } else {

        const payload ={
          company: currentCompany, 
          competitionClass: currentCC, 
          region: currentRegion, 
          courseType: currentCfuType
        };

        enrollmentEvaluationService
          .analyzeEvaluation(payload)
          .then(({ data }) => {
            this.loadStudents();
          })
          .catch((error) => {
            console.log(error);
            if (!networkErrorHelper.is404(error)) {
              networkErrorHelper.notify(error);
            }

            this.setState({ loading: false });
          });
      }
    } else {
      ErrorNotification.render(
        "Devi selezionare un ente per poter proseguire "
      );
    }
  };

  //--- ---
  render() {
    const {
      loading,
      payloadCurrentCC,
      payloadCurrentRegion,
      currentList,
      currentCompany, currentCC, currentCfuType, currentRegion
    } = this.state;

    return (
      <>
        {loading ? (
          <MnemoLoading></MnemoLoading>
        ) : (
          <Container className="m-4">
            <h1>Analisi domande di Ammissione</h1>
            <MSection>
              <Row>
              <Col md={2}>
                  <MSelect {...this.searchField[0]} value={currentCfuType} />
                </Col>                
                <Col md={3}>
                  <MSelect {...this.searchField[1]} value ={currentCompany} />
                </Col>
                <Col md={4}>
                  <MSelect
                  value={currentCC}
                    {...this.searchField[2]}
                    payload={{ options: payloadCurrentCC }}
                  />
                </Col>
                <Col md={2}>
                  <MSelect
                  value={currentRegion}
                    {...this.searchField[3]}
                    payload={{ options: payloadCurrentRegion }}
                  />
                </Col>
                <Col md={1}>
                  <Button
                    color="primary bg-dark"
                    onClick={() => {
                      this.onSearchForm();
                    }}
                  >
                    Cerca
                  </Button>
                </Col>
              </Row>

              <hr />
            </MSection>
            <MSection>
              <h5>Domande di Ammissione</h5>
              {currentList.length === 0 ? (
                <BoxInfo>Non ci sono dati da mostrare in database.</BoxInfo>
              ) : (
                <>
                  <Row>
                    <Button
                      className="ml-2"
                      color="primary bg-dark"
                      onClick={(e) => {
                        this.analyzeStudents(e);
                      }}
                    >
                      <span className="d-button-label">Calcola punteggio</span>
                    </Button>
                  </Row>
                  <Row>
                    <table className="MTable table  table-striped table-hover">
                      <thead>
                        <tr>
                          <th colSpan={3}>Dati anagrafici</th>
                          <th colSpan={3}>Dati domanda</th>
                          <th colSpan={7}>Punteggio</th>
                        </tr>
                        <tr>
                          <th>Nome completo</th>
                          <th>data nascita</th>
                          <th>Contatti</th>

                          <th>codice</th>
                          <th>stato</th>
                          <th>data firma</th>

                          <th>TOTALE</th>
                          <th>Titolo di accesso</th>
                          <th>Ulteriori titoli</th>
                          <th>Master universitari</th>
                          <th>Diploma di specializzazione</th>
                          <th>Dottorato di ricerca</th>
                          <th>Certificazioni linguistiche</th>
                          <th>Servizi insegnamento</th>
                          <th>ERRORI</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{dateHelper.toITDate(item.birthDate)}</td>
                            <td>{item.contacts}</td>

                            <td>{item.orderNumber}</td>
                            <td>{item.currentStep}</td>
                            <td>{dateHelper.toITDate(item.dateSign)}</td>

                            <td>{item.voteSum}</td>
                            <td>{item.vote1}<br/>{item.voteDescription1}</td>
                            <td>{item.vote2}<br/>{item.voteDescription2}</td>
                            <td>{item.vote3}<br/>{item.voteDescription3}</td>
                            <td>{item.vote4}<br/>{item.voteDescription4}</td>
                            <td>{item.vote5}<br/>{item.voteDescription5}</td>
                            <td>{item.vote6}<br/>{item.voteDescription6}</td>
                            <td>{item.vote7}<br/>{item.voteDescription7}</td>
                            <td>{item.warning}</td>                             
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                </>
              )}
            </MSection>
          </Container>
        )}
      </>
    );
  }
}
