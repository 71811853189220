import {proxyBE} from './common';

export const enrollmentEvaluationService = {        

    evaluationList( company, competitionClass, region, courseType ) {        
        return proxyBE.get(`/admin/enrollmentEvaluation/list/${company}/${competitionClass}/${region}/${courseType}` );
    },

    analyzeEvaluation(payload) {
        return proxyBE.post(`/admin/enrollmentEvaluation/analyze/`, payload);
    }

}