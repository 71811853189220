import React from "react";
import { MCheckbox, MInput, MSelect, ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";
import dayjs from "dayjs";
import axios from "axios";

export class TitoliProfessionali extends ValidatedForm {
  PAYLOADS = {
    competitionClass: [{ label: "Seleziona classe di concorso", value: "-1" }],
    grade: [
      { label: "Seleziona grado", value: "" },
      { label: "Secondaria di I Grado", value: "Secondaria di I Grado" },
      { label: "Secondaria di II Grado", value: "Secondaria di II Grado" },
    ],
  };

  onChangeCheck = (id, value) => {
    if (value === true) {
      this.setState({ currentFiledSet: this.FIELDS_GROUP_SHORT });
    } else {
      this.setState({ currentFiledSet: this.FIELDS_GROUP_FULL });
    }
  };

  FIELDS_GROUP_FULL = [
    [
      {
        id: "istitution" + this.props.idx,
        field: "istitution",
        label: "Istituzione Scolastica",
        component: MInput,
        className: "col-md-9 pt-3",
      },
      {
        id: "aa" + this.props.idx,
        field: "aa",
        label: "Anno Scolastico",
        component: MInput,
        className: "col-md-3 pt-3",
        infoText: this.props.checkAA
          ? "inserire formato AAAA/AAAA (solo anni dal 2020/2021 al 2024/2025)"
          : "inserire formato AAAA/AAAA",
      },
    ],
    [
      {
        id: "grado" + this.props.idx,
        field: "grado",
        label: "Grado/ordine di svolgimento del servizio",
        payload: { options: this.PAYLOADS.grade },
        component: MSelect,
        onChange: (_, value) => this.onChangeGrade(_, value),
        className: "col-md-6",
      },
      {
        id: "classeConcorso" + this.props.idx,
        field: "classeConcorso",
        label: "Classe di Concorso",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "ufficioScolastico" + this.props.idx,
        field: "ufficioScolastico",
        label: "Codice meccanografico istituto",
        component: MInput,
        className: "col-md-6",
      },
      {
        id: "contactEmail" + this.props.idx,
        field: "contactEmail",
        label: "E-mail dell’istituto",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "city" + this.props.idx,
        field: "city",
        label: "Comune",
        component: MInput,
        className: "col-md-10",
      },
      {
        id: "province" + this.props.idx,
        field: "province",
        label: "Provincia (sigla)",
        component: MInput,
        className: "col-md-2",
      },
      {
        id: "address" + this.props.idx,
        field: "address",
        label: "Via/Piazza",
        component: MInput,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "dateFrom" + this.props.idx,
        field: "dateFrom",
        label: "dal",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "dateTo" + this.props.idx,
        field: "dateTo",
        label: "al",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "durata" + this.props.idx,
        field: "durata",
        label: "Per un totale di giorni",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
    [
      {
        id: "lode" + this.props.idx,
        field: "lode",
        label:
          "Fino al termine delle operazioni di scrutinio finale ai sensi dell'articolo 11, comma 14, della legge n. 124 del 1999",
        component: MCheckbox,
        className: "col-md-12",
        onChange: this.onChangeCheck,
      },
    ],
  ];

  FIELDS_GROUP_SHORT = [
    [
      {
        id: "istitution" + this.props.idx,
        field: "istitution",
        label: "Istituzione Scolastica",
        component: MInput,
        className: "col-md-9 pt-3",
      },
      {
        id: "aa" + this.props.idx,
        field: "aa",
        label: "Anno Scolastico",
        component: MInput,
        className: "col-md-3 pt-3",
        infoText: this.props.checkAA
          ? "inserire formato AAAA/AAAA (solo anni dal 2020/2021 al 2024/2025)"
          : "inserire formato AAAA/AAAA",
      },
    ],
    [
      {
        id: "grado" + this.props.idx,
        field: "grado",
        label: "Grado/ordine di svolgimento del servizio",
        payload: { options: this.PAYLOADS.grade },
        component: MSelect,
        onChange: (_, value) => this.onChangeGrade(_, value),
        className: "col-md-6",
      },
      {
        id: "classeConcorso" + this.props.idx,
        field: "classeConcorso",
        label: "Classe di Concorso",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "ufficioScolastico" + this.props.idx,
        field: "ufficioScolastico",
        label: "Codice meccanografico istituto",
        component: MInput,
        className: "col-md-6",
      },
      {
        id: "contactEmail" + this.props.idx,
        field: "contactEmail",
        label: "E-mail dell’istituto",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "city" + this.props.idx,
        field: "city",
        label: "Comune",
        component: MInput,
        className: "col-md-10",
      },
      {
        id: "province" + this.props.idx,
        field: "province",
        label: "Provincia (sigla)",
        component: MInput,
        className: "col-md-2",
      },
      {
        id: "address" + this.props.idx,
        field: "address",
        label: "Via/Piazza",
        component: MInput,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "dateFrom" + this.props.idx,
        field: "dateFrom",
        label: "dal",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
    ],
    [
      {
        id: "lode" + this.props.idx,
        field: "lode",
        label:
          "Fino al termine delle operazioni di scrutinio finale ai sensi dell'articolo 11, comma 14, della legge n. 124 del 1999",
        component: MCheckbox,
        className: "col-md-12",
        onChange: this.onChangeCheck,
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Inserire l'Istituzione universitaria o accademica",
    city: "Inserire l'Istituzione universitaria o accademica",
    aa: "Inserire l'anno scolastico (solo anni dal 2020/2021 al 2024/2025)",
    grado: "Inserire il grado/ordine di svolgimento del servizio",
    ufficioScolastico: "Inserire il codice meccanografico dell'istituto",
    classeConcorso: "Inserire la classe di concorso",
    contactEmail: "Inserire l'email dell'istituto",
    province: "Inserire la provincia",
    address: "Inserire l'indirizzo",
    dateFrom: "Inserire la data di inizio",
    dateTo: "Inserire la data di fine",
    durata: "Inserire il numero di giorni oppure numero di giorni non valido",
  };

  validation = {};

  validation_full = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    grado: (value) => value !== "",
    ufficioScolastico: (value) => value !== "" && value.length === 10,
    classeConcorso: (value) => this.validateCompetitionClass(value),
    contactEmail: (value) => value !== "",
    province: (value) => value !== "" && value.length < 4,
    address: (value) => value !== "",
    dateFrom: (value) => this.validateDataFrom(value),
    dateTo: (value) => this.validateDataTo(value),
    durata: (value) => this.validateDuration(value),
    aa: (value) => this.validateAA(value),
  };

  validation_short = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    grado: (value) => value !== "",
    ufficioScolastico: (value) => value !== "" && value.length === 10,
    classeConcorso: (value) => this.validateCompetitionClass(value),
    contactEmail: (value) => value !== "",
    province: (value) => value !== "" && value.length < 4,
    address: (value) => value !== "",
    dateFrom: (value) => this.validateDataFrom(value),
    aa: (value) => this.validateAA(value),
  };

  validateAA = (value) => {

    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");

      if (this.props.checkAA) {
        //--- reset dello switch "lode" ---
        if (parseInt(aa[1]) > 2024) {
          this.setState({ lode: { value: false } });
          this.onChangeCheck("lode" + this.props.idx, false);
        }

        if (parseInt(aa[1]) === 2025) {
          this.setState({
            lode: { value: false },         
          });
          this.onChangeCheck("lode" + this.props.idx, false);
        }

        //--- verifica solo annualità ammesse ---
        if (parseInt(aa[0]) < 2020 || parseInt(aa[1]) > 2025) {
          this.setState({ validationError: "Anno scolastico non ammesso." });
          return false;
        }
      }

      if (parseInt(aa[1]) > new Date().getFullYear()) {
        return false;
      }

      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true
      } else {
        this.setState({
          validationError: "Anno scolastico non valido.",
        });
        return false;
      }
    }
  }; //validateAA

  emptyFields = {
    istitution: "",
    city: "",
    grado: "",
    ufficioScolastico: "",
    classeConcorso: "",
    contactEmail: "",
    province: "",
    address: "",
    dateFrom: "",
    dateTo: "",
    durata: "",
    aa: "",
    lode: false,
  };

  state = {
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    PAYLOADS: [],
    myCurrentGrade: 0,
    currentFiledSet: this.FIELDS_GROUP_FULL,
    validationError: null,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  validateDataTo = (value) => {

    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    const today = new Date();
    const date = new Date(value);

    if (date > today) {
      this.setState({
        validationError: "Deve essere inserita una data valida precedente ad oggi.",
      });
      return false;
    }

    if (typeof this.state.aa.value !== undefined) {
      let aa = this.state.aa?.value ? this.state.aa?.value : null;

      if (aa === null || /^(\d{4})\/(\d{4})$/.test(aa) === false) {
        this.setState({
          validationError: "Deve essere compilato il campo Anno Scolastico.",
        });
        return false;
      }
    }

    const aa = this.state.aa?.value.split("/");

    if (date.getFullYear() < parseInt(aa[0]) || date.getFullYear() > parseInt(aa[1]) ) {
      this.setState({
        validationError:
          `La data di fine deve essere compresa nell'Anno accademico ${this.state.aa?.value}.`,
      });
      return false;
    }

    //--- ---

    if (typeof this.state.aa.value !== undefined) {

      const [year, month, day] = value.split("-").map((num) => parseInt(num));
      const date = new Date(`${year}-${month}-${day}`);

      if (typeof this.state.dateFrom.value !== undefined) {

        const dateFrom = new Date(this.state.dateFrom.value);
        const dateTo = new Date(`${aa[1]}-08-31`);

        if (date < dateFrom || date > dateTo) {
          this.setState({
            validationError:
              `La data di fine deve essere compresa tra il ${this.state.dateFrom.value} e il 31 agosto ${aa[1]}.`,
          });
          return false;
        }

        return true;
      } else {
        // dateFrom not inserted
        return false;
      }
    } else {
      this.setState({
        validationError: "Deve essere compilato il campo Anno Scolastico.",
      });
      return false;
    }
  }

  validateDataFrom = (value) => {

    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    const today = new Date();
    const date = new Date(value);

    if (date > today) {
      this.setState({
        validationError: "Deve essere inserita una data valida precedente ad oggi.",
      });
      return false;
    }

    if (typeof this.state.aa.value !== undefined) {
      let aa = this.state.aa?.value ? this.state.aa?.value : null;

      if (aa === null || /^(\d{4})\/(\d{4})$/.test(aa) === false) {
        this.setState({
          validationError: "Deve essere compilato il campo Anno Scolastico.",
        });
        return false;
      }
    }

    const aa = this.state.aa?.value.split("/");

    if (date.getFullYear() < parseInt(aa[0]) ) {
      this.setState({
        validationError:
          `La data di inizio non può essere precedente al ${aa[0]}.`,
      });
      return false;
    }

    //--- ---

    if (typeof this.state.aa.value !== undefined) {

      const [year, month, day] = value.split("-").map((num) => parseInt(num));
      const date = new Date(`${year}-${month}-${day}`);

      //--- guardia valida solo se lode=true ---
      if (this.state.lode.value === true && value !== "") {

        // per l'ultimo anno non è possibile flaggare la lode 
        if (this.state.aa.value === "2024/2025") {
          this.setState({
            validationError: "Per l'anno accademico 24/25 non può essere dichiarato '..fino al termine delle operazioni di scrutinio finale'.",
          });
          return false;
        }

        const firstFebrary = new Date(`${aa[1]}-02-01 `);

        if (date > firstFebrary) {
          this.setState({
            validationError:
              `La data di inizio deve essere precedente al 1° febbraio ${aa[1]}.`,
          });
          return false;
        }        
          
      } else {

        const dateFrom = new Date(`${aa[0]}-09-01 00:00`);
        const dateTo = new Date(`${aa[1]}-08-31 00:00`);

        if (date < dateFrom || date > dateTo) {
          this.setState({
            validationError:
              `La data di inizio deve essere compresa tra il 1° settembre ${aa[0]} e il 31 agosto ${aa[1]}.`,
          });
          return false;
        }
      }
    } else {
      this.setState({
        validationError: "Deve essere compilato il campo Anno Scolastico.",
      });
      return false;
    }
    return true;
  };

  validateCompetitionClass = (value) => {
    if (value === "-1") {
      return false;
    }

    console.log(this.props);
    if (this.props.skipCC) {
      return true;
    }
  
    if (value === "ADMM" && this.state.myCurrentGrade !== "I GRADO") {
      return false;
    }
    if (value === "ADSS" && this.state.myCurrentGrade !== "II GRADO") {
      return false;
    }

    return true;
  };

  validateDuration = (durata = "") => {
    if (typeof durata === "object" && durata !== null) {
      durata = parseInt(durata.value);
    }

    if (durata === "") {
      return false;
    }

    const { dateFrom, dateTo } = this.state;

    if (dateFrom["value"] === "" || dateTo["value"] === "" || durata === "") {
      return false;
    }

    const diffDays = dayjs(dateTo["value"]).diff(
      dayjs(dateFrom["value"]),
      "day"
    );

    if (isNaN(diffDays)) {
      return false;
    }

    return parseInt(diffDays + 1) === parseInt(durata);
  };

  onChangeGrade = async (_, value) => {
    try {
      const getLocalData = await axios.get(
        "/assets/json/competitionClassAndGrade.json"
      );

      if (getLocalData.data.length > 0) {
        let filterGrade = value;
        if (filterGrade === "Secondaria di I Grado") {
          filterGrade = "I GRADO";
        }
        if (filterGrade === "Secondaria di II Grado") {
          filterGrade = "II GRADO";
        }

        this.PAYLOADS.competitionClass.length = 0;

        getLocalData.data
          .filter((item) => {
            return item.grade === filterGrade;
          })
          .forEach((item) => {
            this.PAYLOADS.competitionClass.push({
              label: item.competitionClass + " - " + item.subject,
              value: item.competitionClass,
            });
          });

        this.PAYLOADS.competitionClass.unshift({
          label: "Seleziona classe di concorso",
          value: "-1",
        });

        this.setState({
          loading: false,
          PAYLOADS: {
            competitionClass: this.PAYLOADS.competitionClass,
          },
        });
      }
    } catch (error) {
      this.setState({ error: true });
    }
  }; //onChangeGrade

  onSubmit = () => {

    let isValid = false;
    this.setState({ validationError: '' });
    isValid = this.checkValidation();

    return isValid;
  }

  componentDidMount() {
    (async () => {
      try {
        const getLocalData = await axios.get(
          "/assets/json/competitionClassAndGrade.json"
        );
        if (getLocalData.data.length > 0) {
          const f = getLocalData.data.filter((item) => {
            return item.competitionClass === this.props.competitionClass;
          });
          this.setState({ myCurrentGrade: f[0].grade });
        }
      } catch (error) {
        this.setState({ error: true });
      }
    })();
  } //componentDidMount

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.qualificationCode = this.props.qualificationCode;
    this.payload.template = this.props.template;
    if (this.payload.lode) {
      this.payload.template += "Lode";
    }
    this.payload.extCode = this.props.extCode;

    if (this.state.university) {
      this.payload.uniCode = this.state.university.uniCode;
    }

    return this.payload;
  };

  renderCurrentForm = () => {
    const { defaultValues, currentFiledSet } = this.state;
    this.validation =
      currentFiledSet === this.FIELDS_GROUP_SHORT
        ? this.validation_short
        : this.validation_full;
    const { code } = this.renderFields(currentFiledSet, defaultValues, true);
    return <>{code}</>;
  };

  render() {
    return (
      <>
        {this.renderCurrentForm()}
        {this.state.validationError && (
          <span className="text-danger">{this.state.validationError}</span>
        )}
      </>
    );
  }
}
