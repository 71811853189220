import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { Button } from "design-react-kit";
import { dateHelper } from "libs";
import { TitoliProfessionali } from "../enroll60CFU/TitoliProfessionali";

export class TitolareContratto3036 extends ValidatedForm {
  _formsContrattoDocenza = [];
  _formVinvitoreProva = null;

  state = {
    checkA: false,
    numContrattoDocenza: [],
    validatedError: "",
  };

  componentDidMount() {
    this._formVinvitoreProva = React.createRef();
  }

  checkAnniEsperienza(payloadToCheck) {

    let validationError = "";

    /* 
          1. le esperienze  hanno aa (da 2020/2021 a 2024/2025)
          2. sono almeno 3 anni di esperinza
          3. almeno un anno di esperienza deve avere la classe corrente
      */
    if (payloadToCheck.length > 0) {

      //si ottiene la lista degli anni in cui si è svolto servizio
      let yearServices = [];
      for (let i = 0; i < payloadToCheck.length; i++) {
        const item = payloadToCheck[i];
        if (yearServices.includes(item.aa)) {
          continue;
        }
        if (item.aa) {
          yearServices.push(item.aa);
        }
      }

      // per ogni aa in cui si è svolto il servizio si calcolano i giorni 
      // lavorativi della stessa classe e di altre classi
      let yearResume = [];
      for (let i = 0; i < yearServices.length; i++) {

        const aa = yearServices[i];
        const aaItems = payloadToCheck.filter((t) => t.aa === aa);

        // order aaItems by dateFrom ASC
        aaItems.sort((a, b) => {
          return a.dateFrom - b.dateFrom;
        });

        let totalAADaysCCSame = 0;
        let totalAADaysCCOther = 0;

        for (let j = 0; j < aaItems.length; j++) {
          const aaItem = aaItems[j];

          // if lode add 180 days and skip this year
          if (aaItem.lode) {
            if (
              aaItem.classeConcorso === this.props.competitionClass
            ) {
              totalAADaysCCSame += 180;
            } else {
              totalAADaysCCOther += 180;
            }
            break;
          }

          // verifica se il periodo corrente ha delle intersezioni con il precedente
          if (j > 0) {
            const prevItem = aaItems[j - 1];
            if (aaItem.dateFrom <= prevItem.dateTo) {
              // rimuove l'intersezione dal calcolo dei giorni
              const intersecation = dateHelper.diffDays(prevItem.dateTo, aaItem.dateFrom);

              if (aaItem.classeConcorso === this.props.competitionClass) {
                totalAADaysCCSame -= intersecation;
              } else {
                totalAADaysCCOther -= intersecation;
              }
            }
          }

          if (aaItem.classeConcorso === this.props.competitionClass) {
            totalAADaysCCSame += parseInt(aaItem.durata);
          } else {
            totalAADaysCCOther += parseInt(aaItem.durata);
          }

        }

        // add the days of curent years
        yearResume.push({ aa: aa, totalAADaysCCSame, totalAADaysCCOther });
      }
      
      let countSame = 0;
      let countOther = 0;
      // calcola il numero di anni di servizio per la stessa classe e per altre classi
      for (let i = 0; i < yearResume.length; i++) {
        const year = yearResume[i];

        if (year.totalAADaysCCSame >= 180) {
          countSame++;
        } else if ((year.totalAADaysCCSame + year.totalAADaysCCOther) >= 180) {
          countOther++;
        }
      }

      if (countSame < 1) {
        validationError = " Almeno un anno di servizio deve essere stato svolto per la stessa classe di concorso per la quale si partecipa alla graduatoria.";
      }

      if ((countSame+countOther) < 3) {
        validationError += " Almeno tre anni di servizio devono essere stati svolti per partecipare alla graduatoria.";
      }

    } else {
      validationError += " E' necessario aggiungere i servizi prestati.";
    }

    return validationError;
  }

  onSubmit = () => {
    let validationError = "";
    let isValid = true;
    const { checkA } = this.state;

    if (!checkA) {
      this.setState({ validationError: "Selezionare la dichiarazione e aggiungere i requisiti professionali." });
      return false;
    } else {
      const payloadToCheck = [];

      if (this._formsContrattoDocenza.length > 0) {
        this._formsContrattoDocenza.forEach((form) => {
          if (form && form.current !== null) {
            isValid = form.current.onSubmit();
            if (!isValid) {
              validationError = "Inserire i dati richiesti.";
            } else {
              const payload = form.current.getPayload();
              payloadToCheck.push(payload);
            }
          } else {
            isValid = false;
            validationError = "Errore generico.";
          }
        });
      }

      if (isValid) {
        validationError = this.checkAnniEsperienza(payloadToCheck);
        if (validationError) {
          isValid = false;
        }
      }
    }    

    this.setState({ validationError: validationError });
    return isValid;
  };

  addContrattoDocenza = () => {
    this.state.numContrattoDocenza.push(
      this.state.numContrattoDocenza.length + 1
    );
    this.setState({
      ...this.state,
      numContrattoDocenza: this.state.numContrattoDocenza,
    });
  };

  removeContrattoDocenza = (idx) => {
    if (this.state.numContrattoDocenza.length <= 0) {
      return true;
    }

    this.state.numContrattoDocenza.splice(idx, 1);
    this._formsContrattoDocenza.splice(idx, 1);

    this.setState({
      ...this.state,
      numContrattoDocenza: this.state.numContrattoDocenza,
    });
  };

  getPayload = () => {
    const payloadTitles = [];
    const { checkA } = this.state;

    if (checkA) {      

      this._formsContrattoDocenza.forEach((form) => {
        if (form && form.current != null) {
          // get payload
          const payload = form.current.getPayload();
          payloadTitles.push(payload);
        }
      });
    } 

    return payloadTitles;
  };

  onChecked = (_, checked) => {
    
    this.setState({ checkA: checked});
    
  };

  render() {
    const { checkA, validationError, numContrattoDocenza } = this.state;

    return (
      <>
        <MCheckbox
          name="checkA"
          id="checkA"
          onChange={this.onChecked}
          label="Dichiaro di aver prestato il seguente servizio presso le istituzioni scolastiche
                 statali o presso le scuole paritarie per almeno tre anni, anche non continuativi, di cui 
                 almeno uno nella specifica classe di concorso per la quale scelgo di conseguire 
                 l’abilitazione nei cinque anni precedenti, valutati ai sensi dell'art. 11, comma 14, 
                 della Legge 3 maggio 1999, n. 124"
        />

        {checkA && (
          <div>
            {(numContrattoDocenza || []).map((_, idx) => {
              this._formsContrattoDocenza[idx] = React.createRef();

              return (
                <div
                  key={`contrattoDocenza=${idx}`}
                  className="m-0 ml-5 mt-5 text-secondary"
                >
                  <h6>Servizio n. {idx + 1}</h6>
                  <Button
                    className="mb-3 btn btn-sm btn-danger"
                    onClick={() => {
                      this.removeContrattoDocenza(idx);
                    }}
                  >
                    Rimuovi
                  </Button>
                  <div className="p-3 border border-secondary rounded-lg">
                    <TitoliProfessionali
                      ref={this._formsContrattoDocenza[idx]}
                      key={`contrattoDocenza=${idx}`}
                      idx={idx}
                      checkAA={true}
                      skipCC={true}
                      competitionClass={this.props.competitionClass}
                      qualificationType="title14Item"
                      qualificationCode="title14Item_TitolareContratto"
                      template="Title14Item_TITOLARE_CONTRATTO"
                      extCode="TITOLARE_CONTRATTO"
                    />
                  </div>
                </div>
              );
            })}
            <div className="mt-3 ml-5 text-secondary">
              <Button
                className="btn btn-sm btn btn-secondary"
                onClick={this.addContrattoDocenza}
              >
                Aggiungi
              </Button>
            </div>
          </div>
        )}       
        {validationError && (
          <div className="text-danger">{validationError}</div>
        )}
      </>
    );
  }
}
